import "./Team.css";

import * as S from "./styles";

import Card from "./Card";
import Fade from "react-reveal/Fade";
import React from "react";
import { Row } from "antd";

function Team({ title, id, profiles }) {
  return (
    <S.TeamBlock>
      <Row type="block" justify="center" align="middle" id={id}>
        <Fade bottom>
          <div
            styles={{
              display: "block",
            }}
          >
            <h6>{title}</h6>
            <div className="grid">
              <Fade bottom cascade>
                {profiles.map((profile) => (
                  <Card
                    key={profile.name}
                    heading={profile.name}
                    paragraph={profile.title}
                    imgUrl={profile.image}
                    email={profile.email}
                  ></Card>
                ))}
              </Fade>
            </div>
          </div>
        </Fade>
      </Row>
    </S.TeamBlock>
  );
}

export default Team;
